<template>
  <div>
    <b-row class="row mb-4 mt-5">
      <b-col cols="12" offset-sm="1" sm="10">
        <div class="d-flex justify-content-between">
          <h3>{{ $t('settings.analytics.name') }}</h3>
          <date-range-picker
            ref="picker"
            opens="left"
            :locale-data="localeData"
            :minDate="minDate"
            :singleDatePicker="false"
            :showWeekNumbers="true"
            :showDropdowns="true"
            :autoApply="false"
            v-model="dateRange"
            @update="updateValues"
          >
            <template v-slot:input="picker">
              <div style="min-width: 350px;">
                {{ picker.startDate | inputDate }} - {{ picker.endDate | inputDate }}
                <b-icon class="pl-1 dropdown-icon" icon="caret-down-fill" aria-hidden="true"></b-icon>
              </div>
            </template>
          </date-range-picker>
        </div>
        <hr>
        <p class="mb-5" v-html="$t('settings.analytics.desc')"></p>
      </b-col>
      <b-col cols="12" offset-sm="1" sm="10">
        <b-form-checkbox
          id="use-fake-data"
          name="use-fake-data"
          size="lg"
          v-model="useFakeData"
          :value="true"
          :unchecked-value="false"
          @change="activateFakeData"
        >
          Use Fake Data
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" offset-sm="1" sm="10">
        <b-row v-if="queryErrors.globalStats" class="global-stats mb-5">
          <b-col cols="12">
            <h4>Global stats</h4>
            <div class="query-error">
              {{ $t('settings.analytics.queryError') }}
            </div>
          </b-col>
        </b-row>
        <b-row v-else-if="globalStats" class="global-stats mb-5">
          <b-col cols="12" sm="3">
            <h5>
              {{ $t('settings.analytics.visitors') }}
              <b-icon
                style="margin-left: 3px;"
                id="tooltip-global-visitors"
                icon="question-circle"
                aria-hidden="true">
              </b-icon>
              <b-tooltip
                target="tooltip-global-visitors"
              >
                {{ $t('settings.analytics.visitorsTooltip') }}
              </b-tooltip>
            </h5>
            <div>{{ globalStats.visitors }}</div>
          </b-col>
          <b-col cols="12" sm="3">
            <h5>
              {{ $t('settings.analytics.views') }}
              <b-icon
                style="margin-left: 3px;"
                id="tooltip-global-views"
                icon="question-circle"
                aria-hidden="true">
              </b-icon>
              <b-tooltip
                target="tooltip-global-views"
              >
                {{ $t('settings.analytics.viewsTooltip') }}
              </b-tooltip>
            </h5>
            <div>{{ globalStats.views }}</div>
          </b-col>
          <b-col cols="12" sm="3">
            <h5>
              {{ $t('settings.analytics.avgTime') }}
              <b-icon
                style="margin-left: 3px;"
                id="tooltip-global-avg-time"
                icon="question-circle"
                aria-hidden="true">
              </b-icon>
              <b-tooltip
                target="tooltip-global-avg-time"
              >
                {{ $t('settings.analytics.avgTimeTooltip') }}
              </b-tooltip>
            </h5>
            <div>{{ timeOnPage }}</div>
          </b-col>
          <b-col cols="12" sm="3">
            <h5 id="global-bounce-rate">
              {{ $t('settings.analytics.bounceRate') }}
              <b-icon
                style="margin-left: 3px;"
                id="tooltip-global-bounce-rate"
                icon="question-circle"
                aria-hidden="true">
              </b-icon>
              <b-tooltip
                target="tooltip-global-bounce-rate"
              >
                {{ $t('settings.analytics.bounceRateTooltip') }}
              </b-tooltip>
            </h5>
            <div>{{ globalStats.bounceRate }}</div>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <h4>{{ $t('settings.analytics.readsPerDay') }}</h4>
            <div v-if="queryErrors.readsPerDayObject" class="query-error">
              {{ $t('settings.analytics.queryError') }}
            </div>
            <LineChart
              v-else-if="readsPerDayObject"
              :key="key"
              :reads-per-day-object="readsPerDayObject"
              :dateRange="dateRange"
            />
          </b-col>
        </b-row>
        <b-row v-if="queryErrors.projectOrFolders" class="mb-5">
          <b-col cols="12">
            <h4>{{ $t('settings.analytics.projectsOrFolders') }}</h4>
            <div class="query-error">
              {{ $t('settings.analytics.queryError') }}
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-5" v-else-if="itemType !== 'project' && projectOrFolders.length">
          <b-col>
            <h4>{{ $t('settings.analytics.projectsOrFolders') }}</h4>
            <b-table
              :key="folderOrProjectTableKey"
              :fields="projectAndFolderFields"
              :items="projectOrFolders"
              borderless
              dark
              fixed
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.width }"
                >
              </template>
              <template #head(entries)>
                <div class="text-nowrap" id="tooltip-folder-project-entries">
                  {{ $t('settings.analytics.visitors') }}
                  <b-tooltip
                    target="tooltip-folder-project-entries"
                  >
                    {{ $t('settings.analytics.visitorsTooltip') }}
                  </b-tooltip>
                </div>
              </template>
              <template #head(viewers)>
                <div class="text-nowrap" id="tooltip-folder-project-viewers">
                  {{ $t('settings.analytics.viewers') }}
                  <b-tooltip
                    target="tooltip-folder-project-viewers"
                  >
                    {{ $t('settings.analytics.viewersTooltip') }}
                  </b-tooltip>
                </div>
              </template>
              <template #head(views)>
                <div class="text-nowrap" id="tooltip-folder-project-views">
                  {{ $t('settings.analytics.views') }}
                  <b-tooltip
                    target="tooltip-folder-project-views"
                  >
                    {{ $t('settings.analytics.viewsTooltip') }}
                  </b-tooltip>
                </div>
              </template>
              <template #head(avgReadingTime)>
                <div class="text-nowrap" id="tooltip-folder-project-avgReadingTime">
                  {{ $t('settings.analytics.avgTime') }}
                  <b-tooltip
                    target="tooltip-folder-project-avgReadingTime"
                  >
                    {{ $t('settings.analytics.avgTimeTooltip') }}
                  </b-tooltip>
                </div>
              </template>
              <template #head(bounceRate)>
                <div class="text-nowrap" id="tooltip-folder-project-bounceRate">
                  {{ $t('settings.analytics.bounceRate') }}
                  <b-tooltip
                    target="tooltip-folder-project-bounceRate"
                  >
                    {{ $t('settings.analytics.bounceRateTooltip') }}
                  </b-tooltip>
                </div>
              </template>
              <template #cell(image)="data">
                <div
                  v-if="data.item.type === 'folder'"
                  class="cell-folder cell-background-image d-flex
            justify-content-center align-items-center"
                >
                  <b-icon
                    icon="folder"
                    aria-hidden="true"
                    scale="1"
                  ></b-icon>
                </div>
                <div
                  v-if="data.item.type === 'project' && !data.item.image"
                  class="cell-background-image d-flex justify-content-center align-items-center"
                >
                  <b-icon
                    icon="files"
                    aria-hidden="true"
                    scale="1"
                  ></b-icon>
                </div>
                <div
                  v-if="data.item.type === 'project' && data.item.image"
                  class="cell-background-image"
                  :style="`background-image: url(${data.item.image})`"
                >
                </div>
              </template>
              <template #cell(avgReadingTime)="data">
                {{ data.item.avgReadingTime | timeSting }}
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row v-if="queryErrors.pages" class="mb-5">
          <b-col cols="12">
            <h4>{{ $t('settings.analytics.pages') }}</h4>
            <div class="query-error">
              {{ $t('settings.analytics.queryError') }}
            </div>
          </b-col>
        </b-row>
        <b-row v-else-if="pages && pages.length" class="mb-5">
          <b-col>
            <h4>{{ $t('settings.analytics.pages') }}</h4>
            <b-table
              :key="pageTableKey"
              :fields="fields"
              :items="pages"
              borderless
              dark
              fixed
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.width }"
                >
              </template>
              <template #head(entries)>
                <div class="text-nowrap" id="tooltip-page-entries">
                  {{ $t('settings.analytics.entries') }}
                  <b-tooltip
                    target="tooltip-page-entries"
                  >
                    {{ $t('settings.analytics.entriesTooltip') }}
                  </b-tooltip>
                </div>
              </template>
              <template #head(viewers)>
                <div class="text-nowrap" id="tooltip-page-viewers">
                  {{ $t('settings.analytics.pageViewers') }}
                  <b-tooltip
                    target="tooltip-page-viewers"
                  >
                    {{ $t('settings.analytics.viewersTooltip') }}
                  </b-tooltip>
                </div>
              </template>
              <template #head(views)>
                <div class="text-nowrap" id="tooltip-page-views">
                  {{ $t('settings.analytics.views') }}
                  <b-tooltip
                    target="tooltip-page-views"
                  >
                    {{ $t('settings.analytics.viewsTooltip') }}
                  </b-tooltip>
                </div>
              </template>
              <template #head(avgReadingTime)>
                <div class="text-nowrap" id="tooltip-page-avgReadingTime">
                  {{ $t('settings.analytics.avgPageTime') }}
                  <b-tooltip
                    target="tooltip-page-avgReadingTime"
                  >
                    {{ $t('settings.analytics.avgPageTimeTooltip') }}
                  </b-tooltip>
                </div>
              </template>
              <template #head(bounceRate)>
                <div class="text-nowrap" id="tooltip-page-bounceRate">
                  {{ $t('settings.analytics.bounceRate') }}
                  <b-tooltip
                    target="tooltip-page-bounceRate"
                  >
                    {{ $t('settings.analytics.bounceRateTooltip') }}
                  </b-tooltip>
                </div>
              </template>
              <template #cell(image)="data">
                <div
                  class="cell-background-image"
                  :style="{
                    backgroundImage: data.item.imageObject.imageUrl,
                    backgroundPosition: data.item.imageObject.focalpoint,
                }"
                ></div>
              </template>
              <template #cell(avgReadingTime)="data">
                {{ data.item.avgReadingTime | timeSting }}
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <h4>{{ $t('settings.analytics.referrers') }}</h4>
            <div v-if="queryErrors.referrers" class="query-error">
              {{ $t('settings.analytics.queryError') }}
            </div>
            <b-table
              v-else
              :fields="fieldReferrers"
              :items="referrers"
              borderless
              show-empty
              dark
              fixed
            >
              <template #empty>
                <div>{{ $t('settings.analytics.noResult') }}</div>
              </template>
              <template #head(visitors)>
                <div class="text-nowrap" id="tooltip-referrers-visitors">
                  {{ $t('settings.analytics.visitors') }}
                  <b-tooltip
                    target="tooltip-referrers-visitors"
                  >
                    {{ $t('settings.analytics.visitorsReferrerTooltip') }}
                  </b-tooltip>
                </div>
              </template>
              <template #head(views)>
                <div class="text-nowrap" id="tooltip-referrers-views">
                  {{ $t('settings.analytics.views') }}
                  <b-tooltip
                    target="tooltip-referrers-views"
                  >
                    {{ $t('settings.analytics.viewsReferrerTooltip') }}
                  </b-tooltip>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col cols="6">
            <h4>{{ $t('settings.analytics.deviceType') }}</h4>
            <div v-if="queryErrors.deviceTypes" class="query-error">
              {{ $t('settings.analytics.queryError') }}
            </div>
            <b-table
              v-else
              :fields="fieldDeviceType"
              :items="deviceTypes"
              borderless
              show-empty
              dark
              fixed
            >
              <template #empty>
                <div>{{ $t('settings.analytics.noResult') }}</div>
              </template>
            </b-table>
          </b-col>
          <b-col cols="6">
            <h4>{{ $t('settings.analytics.countries') }}</h4>
            <div v-if="queryErrors.countries" class="query-error">
              {{ $t('settings.analytics.queryError') }}
            </div>
            <b-table
              v-else
              :fields="fieldCountries"
              :items="countries"
              borderless
              show-empty
              dark
              fixed
              class="country-table"
            >
              <template #empty>
                <div>{{ $t('settings.analytics.noResult') }}</div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SetLoadingIndicator from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import GetAllChildPageIdsOfFolder from '@/graphQlQueries/queries/getAllChildPageIdsOfFolder';
import GetAllChildPageIdsOfProject from '@/graphQlQueries/queries/getAllChildPageIdsOfProject';
import GetAllFoldersAndProjectsByFolderId from '@/graphQlQueries/queries/getAllFoldersAndProjectsByFolderId';
import GetPirschData from '@/graphQlQueries/queries/getPirschData';
// import axios from 'axios';
import DateRangePicker from 'vue2-daterange-picker';
import LineChart from '@/components/settings/analytics/LineChart.vue';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
  name: 'PirschAnalyticsDashboard',
  components: { DateRangePicker, LineChart },
  props: {
    pirschCode: {
      type: String,
      required: null,
    },
    itemId: {
      type: Number,
      default: null,
    },
    itemType: {
      type: String,
      default: null,
    },
    showReadersInTable: {
      type: Boolean,
      default: false,
    },
    isSysAdmin: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.pirschId = this.pirschCode;
    this.itemPathId = this.getItemPathId();
    this.$apollo.mutate({
      mutation: SetLoadingIndicator,
      variables: {
        isIndicatorLoading: true,
      },
    });
    this.path = `/${this.$route.params.parametersOfUrl}/`;
    this.path = (this.$route.params.parametersOfUrl)
      ? `/${this.$route.params.parametersOfUrl}/`
      : '/';
    const date = new Date();
    date.setHours(23, 59, 59, 0);
    this.dateRange.endDate = date.toISOString();
    date.setMonth(date.getMonth() - 1);
    date.setHours(0, 0, 0, 0);
    // because of an analytics frontend bug set the min date to 2022-06-21
    this.minDate = new Date('2022-06-21T00:00:00').toISOString();
    const startDate = date.toISOString();
    if ((startDate < this.minDate) && !this.isSysAdmin) {
      this.dateRange.startDate = this.minDate;
    } else {
      this.dateRange.startDate = startDate;
      this.minDate = new Date('1995-12-17T03:24:00');
    }
  },
  apollo: {
    globalStats: {
      query: GetPirschData,
      variables() {
        return {
          obj: {
            endpoint: '/statistics/total',
            params: {
              id: this.pirschId,
              from: this.dateRange.startDate.split('T')[0],
              to: this.dateRange.endDate.split('T')[0],
              pattern: this.itemPathId,
            },
          },
        };
      },
      update(data) {
        this.queryErrors.globalStats = false;
        if (data.getPirschData.result) {
          const globalStats = data.getPirschData.result;
          const visitors = (globalStats.visitors)
            ? globalStats.visitors
            : '-';
          const views = (globalStats.views)
            ? globalStats.views
            : '-';
          const avgTime = (globalStats.avg_duration)
            ? globalStats.avg_duration
            : 0;
          const bounceRate = (globalStats.bounce_rate)
            ? `${Math.round(parseFloat(globalStats.bounce_rate) * 100)}%`
            : '-';
          return {
            visitors,
            views,
            avgTime,
            bounceRate,
          };
        }
        return null;
      },
      fetchPolicy: 'network-only',
      skip() {
        return !this.path || !this.pirschId;
      },
      error(error) {
        this.queryErrors.globalStats = true;
        console.log('Error globalStats', error);
      },
    },
    timeOnPage: {
      query: GetPirschData,
      variables() {
        return {
          obj: {
            endpoint: '/statistics/duration/session',
            params: {
              id: this.pirschId,
              from: this.dateRange.startDate.split('T')[0],
              to: this.dateRange.endDate.split('T')[0],
              pattern: this.itemPathId,
              scale: 'year',
            },
          },
        };
      },
      update(data) {
        if (data.getPirschData.result) {
          let addedTime = 0;
          let counter = 0;
          data.getPirschData.result.forEach((item) => {
            if (item.average_time_spent_seconds) {
              addedTime += item.average_time_spent_seconds;
              counter += 1;
            }
          });
          return (counter > 0)
            ? `${(Math.round(addedTime)) / counter} sec`
            : 0;
        }
        return '-';
      },
      fetchPolicy: 'network-only',
      skip() {
        return !this.path || !this.pirschId;
      },
      error(error) {
        this.queryErrors.globalStats = true;
        console.log('Error timeOnPage', error);
      },
    },
    readsPerDayObject: {
      query: GetPirschData,
      variables() {
        return {
          obj: {
            endpoint: '/statistics/visitor',
            params: {
              id: this.pirschId,
              from: this.dateRange.startDate.split('T')[0],
              to: this.dateRange.endDate.split('T')[0],
              pattern: this.itemPathId,
            },
          },
        };
      },
      update(data) {
        this.queryErrors.readsPerDayObject = false;
        if (data.getPirschData.result) {
          const readsPerDay = data.getPirschData.result;
          // eslint-disable-next-line no-nested-ternary
          readsPerDay.sort((a, b) => ((a.day > b.day) ? 1 : ((b.day > a.day) ? -1 : 0)));
          return readsPerDay.map((item) => ({
            label: item.day.split('T')[0],
            value: item.views,
          }));
        }
        return null;
      },
      fetchPolicy: 'network-only',
      skip() {
        return !this.path || !this.pirschId;
      },
      error(error) {
        this.queryErrors.readsPerDayObject = true;
        console.log('Error readsPerDayObject', error);
      },
    },
    referrers: {
      query: GetPirschData,
      variables() {
        return {
          obj: {
            endpoint: '/statistics/referrer',
            params: {
              id: this.pirschId,
              from: this.dateRange.startDate.split('T')[0],
              to: this.dateRange.endDate.split('T')[0],
              pattern: this.itemPathId,
            },
          },
        };
      },
      update(data) {
        this.queryErrors.referrers = false;
        if (data.getPirschData.result) {
          const referrers = data.getPirschData.result;
          // eslint-disable-next-line no-nested-ternary
          referrers.sort((a, b) => ((a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0)));
          return referrers.map((item) => ({
            name: (item.referrer_name) ? item.referrer_name : 'Direct/Unknown',
            visitors: item.visitors,
            views: item.sessions,
          }));
        }
        return null;
      },
      fetchPolicy: 'network-only',
      skip() {
        return !this.path || !this.pirschId;
      },
      error(error) {
        this.queryErrors.referrers = true;
        console.log('Error referrers', error);
      },
    },
    deviceTypes: {
      query: GetPirschData,
      variables() {
        return {
          obj: {
            endpoint: '/statistics/platform',
            params: {
              id: this.pirschId,
              from: this.dateRange.startDate.split('T')[0],
              to: this.dateRange.endDate.split('T')[0],
              pattern: this.itemPathId,
            },
          },
        };
      },
      update(data) {
        this.queryErrors.deviceTypes = false;
        if (data.getPirschData.result) {
          const deviceData = data.getPirschData.result;
          const deviceType = [];
          deviceType.push({
            name: 'Desktop',
            visitors: `${Math.round(deviceData.relative_platform_desktop * 100)}%`,
          });
          deviceType.push({
            name: 'Mobile',
            visitors: `${Math.round(deviceData.relative_platform_mobile * 100)}%`,
          });
          deviceType.push({
            name: 'Unknown',
            visitors: `${Math.round(deviceData.relative_platform_unknown * 100)}%`,
          });
          return deviceType;
        }
        return null;
      },
      fetchPolicy: 'network-only',
      skip() {
        return !this.path || !this.pirschId;
      },
      error(error) {
        this.queryErrors.deviceTypes = true;
        console.log('Error deviceTypes', error);
      },
    },
    countries: {
      query: GetPirschData,
      variables() {
        return {
          obj: {
            endpoint: '/statistics/country',
            params: {
              id: this.pirschId,
              from: this.dateRange.startDate.split('T')[0],
              to: this.dateRange.endDate.split('T')[0],
              pattern: this.itemPathId,
            },
          },
        };
      },
      update(data) {
        this.queryErrors.countries = false;
        if (data.getPirschData.result) {
          const totalVisitors = data.getPirschData.result.reduce(
            (acc, item) => acc + item.visitors, 0,
          );
          if (totalVisitors === 0) {
            return null;
          }
          const countryResult = data.getPirschData.result.map((item) => ({
            name: item.country_code,
            readers: `${Math.round((item.visitors / totalVisitors) * 100)}%`,
          }));
          // sort the countries by relative_visitors
          countryResult.sort((a, b) => (
            // eslint-disable-next-line no-nested-ternary
            (a.readers > b.readers)
              ? -1
              : ((b.readers > a.readers) ? 1 : 0)
          ));
          return countryResult;
        }
        return null;
      },
      fetchPolicy: 'network-only',
      skip() {
        return !this.path || !this.pirschId;
      },
      error(error) {
        this.queryErrors.countries = true;
        console.log('Error countries', error);
      },
    },
  },
  filters: {
    timeSting(value) {
      if (!value) return '0';
      // eslint-disable-next-line no-param-reassign
      const totalSeconds = Math.floor(value);
      const seconds = totalSeconds % 60;
      const minutes = Math.floor(totalSeconds / 60);
      return (minutes > 0)
        ? `${minutes}min ${seconds}s`
        : `${seconds}s`;
    },
    inputDate(value) {
      if (!value) return value;
      const timestamp = new Date(value);
      if (!timestamp) {
        return value;
      }
      const month = timestamp.getMonth() + 1;
      const day = timestamp.getDate();
      return `${day}.${month}`;
    },
  },
  async mounted() {
    if (this.itemType !== 'project') {
      this.getTheProjectAndFolderData();
    }
    this.getThePageData();
  },
  methods: {
    getItemPathId() {
      switch (this.itemType) {
        case 'folder':
          return `/f${this.itemId}`;
        case 'project':
          return `/pr${this.itemId}`;
        default:
          return '/';
      }
    },
    activateFakeData(isFakeActive) {
      this.$apollo.mutate({
        mutation: SetLoadingIndicator,
        variables: {
          isIndicatorLoading: true,
        },
      });
      if (isFakeActive) {
        this.itemPathId = '/pr846';
        this.pirschId = 'MZgmbpJ1L5';
        this.dateRange.startDate = new Date('2024-03-01T00:00:00').toISOString();
        this.dateRange.endDate = new Date('2024-04-01T00:00:00').toISOString();
        this.fakeItemPaths = [
          '/f364/f366',
          '/f364/f366/pr846',
        ];
        this.fakePages = [
          '/f364/f366/pr846/pa4534',
          '/f364/f366/pr846/pa4522',
          '/f364/f366/pr846/pa4530',
        ];
        // set the fake data and run the queries
        this.$apollo.mutate({
          mutation: SetLoadingIndicator,
          variables: {
            isIndicatorLoading: true,
          },
        });
        this.runQueries();
      } else {
        this.itemPathId = this.getItemPathId();
        this.pirschId = this.pirschCode;
        this.runQueries();
      }
    },
    updateValues(dateRange) {
      dateRange.startDate.setHours(0, 0, 0, 0);
      this.dateRange.startDate = dateRange.startDate.toISOString();
      dateRange.endDate.setHours(23, 59, 59, 0);
      this.dateRange.endDate = dateRange.endDate.toISOString();
      if (this.itemType !== 'project') {
        this.getTheProjectAndFolderData();
      }
      this.getThePageData();
      this.$apollo.mutate({
        mutation: SetLoadingIndicator,
        variables: {
          isIndicatorLoading: true,
        },
      });
      this.runQueries();
    },
    runQueries() {
      this.$apollo.queries.globalStats.refetch();
      this.$apollo.queries.timeOnPage.refetch();
      this.readsPerDayObject = null;
      this.$apollo.queries.readsPerDayObject.refetch();
      this.$apollo.queries.referrers.refetch();
      this.$apollo.queries.deviceTypes.refetch();
      this.$apollo.queries.countries.refetch();
      this.getTheProjectAndFolderData();
      this.getThePageData();
    },
    getTheProjectAndFolderData() {
      this.$apollo.query({
        query: GetAllFoldersAndProjectsByFolderId,
        variables: {
          folderId: this.itemId,
        },
        fetchPolicy: 'network-only',
      }).then((result) => {
        // build the unique array for the async requests
        const childItems = [];
        result.data.folders.forEach((folder) => {
          childItems.push({
            name: folder.name,
            slug: folder.slug,
            order_index: folder.order_index,
            thumbnails: null,
            item_path: folder.item_path,
            type: 'folder',
          });
        });
        result.data.projects.forEach((project) => {
          childItems.push({
            name: project.name,
            slug: project.slug,
            order_index: project.order_index,
            thumbnails: project.thumbnails,
            item_path: project.item_path,
            type: 'project',
          });
        });
        this.getTheFathomDataForChildFoldersAndProjects(childItems);
      }).catch((error) => {
        console.log('Error', error);
      });
    },
    getThePageData() {
      if (this.itemType === 'project') {
        this.$apollo.query({
          query: GetAllChildPageIdsOfProject,
          variables: {
            projectId: this.itemId,
          },
          fetchPolicy: 'network-only',
        }).then((result) => {
          if (result.data.projects.length) {
            const childPages = result.data.projects[0].child_pages;
            this.getPirschDataForChildElements().then((pirschData) => {
              if (pirschData) {
                this.setChildPageData(childPages, pirschData);
              } else {
                this.$apollo.mutate({
                  mutation: SetLoadingIndicator,
                  variables: {
                    isIndicatorLoading: false,
                  },
                });
              }
            });
          }
        }).catch((error) => {
          console.log(error);
        });
      } else {
        this.$apollo.query({
          query: GetAllChildPageIdsOfFolder,
          variables: {
            folderId: this.itemId,
          },
          fetchPolicy: 'network-only',
        }).then((result) => {
          if (result.data.folders.length) {
            const childPages = result.data.folders[0].child_pages;
            this.getPirschDataForChildElements().then((pirschData) => {
              if (pirschData) {
                this.setChildPageData(childPages, pirschData);
              } else {
                this.$apollo.mutate({
                  mutation: SetLoadingIndicator,
                  variables: {
                    isIndicatorLoading: false,
                  },
                });
              }
            });
          }
        }).catch((error) => {
          console.log(error);
        });
      }
    },
    setChildPageData(childPages, pirschData) {
      this.pages = childPages.map((childPage, index) => {
        const imageObject = {
          imageUrl: this.getPageImageUrl(childPage.thumbnails),
          focalpoint: (childPage.thumbnails?.image?.focalpoint)
            ? `${childPage.thumbnails.image.focalpoint.x}% ${childPage.thumbnails.image.focalpoint.y}%`
            : null,
        };
        let itemPath = `/${childPage.item_path.replace(/\./g, '/')}`;
        if (this.useFakeData) {
          itemPath = this.fakePages[index];
        }
        const pirschPageData = pirschData.find((element) => element.path === itemPath);
        if (pirschPageData) {
          return {
            name: childPage.name,
            entries: (pirschPageData.relative_views) ? `${Math.round(Number(pirschPageData.relative_views) * 100)}%` : '-',
            viewers: (pirschPageData.visitors) ? parseInt(pirschPageData.visitors, 10) : 0,
            views: (pirschPageData.views) ? parseInt(pirschPageData.views, 10) : 0,
            avgReadingTime: pirschPageData.average_time_spent_seconds,
            bounceRate: (pirschPageData.bounce_rate) ? `${Math.round(Number(pirschPageData.bounce_rate) * 100)}%` : '-',
            imageObject,
          };
        }
        return null;
      }).filter((item) => item !== null);
      this.$apollo.mutate({
        mutation: SetLoadingIndicator,
        variables: {
          isIndicatorLoading: false,
        },
      });
    },
    getPirschDataForChildElements() {
      return new Promise((resolve) => {
        if (this.pirschId) {
          this.$apollo.query({
            query: GetPirschData,
            variables: {
              obj: {
                endpoint: '/statistics/page',
                params: {
                  id: this.pirschId,
                  from: this.dateRange.startDate.split('T')[0],
                  to: this.dateRange.endDate.split('T')[0],
                  pattern: this.itemPathId,
                  include_avg_time_on_page: true,
                },
              },
            },
            fetchPolicy: 'network-only',
          }).then((result) => {
            if (result.data.getPirschData.result) {
              resolve(result.data.getPirschData.result);
            } else {
              resolve(null);
            }
          }).catch((error) => {
            console.log('projectOrFolders error', error);
            this.queryErrors.projectOrFolders = true;
            resolve(null);
          });
        } else {
          resolve(null);
        }
      });
    },
    getTheFathomDataForChildFoldersAndProjects(childItems) {
      // get all the data for the child folders and projects
      // I do have an array of all the pages of the element
      Promise.all(childItems.map(
        (childItem, index) => {
          let imageUrl = null;
          if (childItem.type === 'project' && childItem.thumbnails?.cover_image?.url) {
            imageUrl = childItem.thumbnails.cover_image.url;
          }
          let itemPath = `/${childItem.item_path.replace(/\./g, '/')}`;
          if (this.useFakeData) {
            itemPath = this.fakeItemPaths[index];
          }
          // do something like return new Promise
          return this.getChildItemData(
            childItem.name, childItem.type, itemPath, imageUrl, childItem.order_index,
          );
        },
      )).then((responses) => {
        const allFoldersAndProjects = [];
        responses.forEach((response) => {
          if (response) {
            const item = {
              name: response.name,
              type: response.type,
              entries: (response.entries !== null) ? response.entries : 0,
              viewers: (response.viewers !== null) ? response.viewers : 0,
              views: (response.views !== null) ? response.views : 0,
              bounceRate: (response.bounceRate !== null) ? response.bounceRate : '-',
              avgReadingTime: (response.avgReadingTime !== null) ? response.avgReadingTime : 0,
              image: response.imageObject,
              order_index: response.order_index,
            };
            if (this.showReadersInTable) {
              item.readers = response.readers;
            }
            allFoldersAndProjects.push(item);
          }
        });
        // eslint-disable-next-line max-len,no-nested-ternary
        allFoldersAndProjects.sort((a, b) => ((a.order_index > b.order_index) ? 1 : ((b.order_index > a.order_index) ? -1 : 0)));
        this.projectOrFolders = allFoldersAndProjects;
        this.$apollo.mutate({
          mutation: SetLoadingIndicator,
          variables: {
            isIndicatorLoading: false,
          },
        });
      }).catch((error) => {
        console.log(error);
      });
    },
    getChildItemData(name, type, itemPath, imageObject, order_index) {
      return new Promise((resolve) => {
        if (this.pirschId) {
          this.$apollo.query({
            query: GetPirschData,
            variables: {
              obj: {
                endpoint: '/statistics/total',
                params: {
                  id: this.pirschId,
                  from: this.dateRange.startDate.split('T')[0],
                  to: this.dateRange.endDate.split('T')[0],
                  pattern: itemPath,
                },
              },
            },
            fetchPolicy: 'network-only',
          }).then((result) => {
            if (result.data.getPirschData.result) {
              const stats = result.data.getPirschData.result;
              const avgTime = (stats.avg_duration)
                ? stats.avg_duration
                : 0;
              const bounceRate = (stats.bounce_rate)
                ? `${Math.round(parseFloat(stats.bounce_rate) * 100)}%`
                : '-';
              resolve({
                name,
                imageObject,
                type,
                order_index,
                entries: (stats.visitors) ? stats.visitors : null,
                views: (stats.views) ? stats.views : null,
                avgTime,
                bounceRate,
              });
            } else {
              resolve(null);
            }
          }).catch((error) => {
            console.log('projectOrFolders error', error);
            this.queryErrors.projectOrFolders = true;
            resolve(null);
          });
        } else {
          resolve(null);
        }
      });
    },
    getPageImageUrl(thumbnail) {
      let cloudImageUrl = null;
      if (thumbnail?.image?.url) {
        cloudImageUrl = this.$makeCloudImage(
          thumbnail.image.url,
          {
            width: 210,
            height: 150,
          },
          thumbnail.image,
        );
      }
      return (thumbnail?.image?.url)
        ? `url(${cloudImageUrl})`
        // eslint-disable-next-line global-require
        : `url(${require('@/assets/thumb-default.png')})`;
    },
  },
  data() {
    return {
      fakeItemPaths: [],
      fakePages: [],
      pirschId: null,
      useFakeData: false,
      itemPathId: null,
      queryErrors: {
        globalStats: false,
        timeOnPage: false,
        readsPerDayObject: false,
        referrers: false,
        deviceTypes: false,
        countries: false,
        projectOrFolders: false,
        pages: false,
      },
      key: 0,
      readsPerDayObject: null,
      isBusyLoadingPages: true,
      client: null,
      path: null,
      enableQuery: false,
      timezone: 'Europe/Berlin',
      localeData: {
        direction: 'ltr',
        format: 'dd/mm/yyyy',
        separator: ' - ',
        applyLabel: this.$t('buttons.apply'),
        cancelLabel: this.$t('buttons.cancel'),
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        firstDay: 0,
      },
      dateRange: {
        startDate: null,
        endDate: null,
      },
      minDate: null,
      globalStats: null,
      timeOnPage: null,
      folderOrProjectTableKey: 0,
      projectOrFolders: [],
      pageTableKey: 0,
      projectAndFolderFields: [
        {
          key: 'image',
          label: '',
          sortable: false,
          width: '50px',
          thClass: 'image',
          tdClass: 'image',
        },
        {
          key: 'name',
          label: 'Name',
          sortable: false,
          width: 'auto',
        },
        {
          key: 'entries',
          sortable: true,
          width: '80px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'views',
          sortable: true,
          width: '90px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'avgReadingTime',
          sortable: true,
          width: '140px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'bounceRate',
          sortable: true,
          width: '120px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      fields: [
        {
          key: 'image',
          label: '',
          sortable: false,
          width: '50px',
          thClass: 'image',
          tdClass: 'image',
        },
        {
          key: 'name',
          label: 'Name',
          sortable: false,
          width: 'auto',
        },
        {
          key: 'viewers',
          sortable: true,
          width: '90px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'views',
          sortable: true,
          width: '90px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'avgReadingTime',
          sortable: true,
          width: '140px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'bounceRate',
          sortable: true,
          width: '120px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'entries',
          sortable: true,
          width: '80px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      pages: [],
      fieldReferrers: [
        {
          key: 'name',
          label: 'Name',
          sortable: false,
          width: 'auto',
        },
        {
          key: 'visitors',
          sortable: true,
          width: '110px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'views',
          sortable: true,
          width: '110px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      referrers: null,
      fieldDeviceType: [
        {
          key: 'name',
          label: 'Name',
          sortable: false,
          width: 'auto',
        },
        {
          key: 'visitors',
          label: this.$t('settings.analytics.visitors'),
          sortable: true,
          width: '110px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      deviceTypes: [],
      fieldCountries: [
        {
          key: 'name',
          label: '',
          sortable: false,
          width: 'auto',
        },
        {
          key: 'readers',
          label: this.$t('settings.analytics.readers'),
          sortable: true,
          width: '110px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      countries: [],
    };
  },
};
</script>

<style>
@media print {
  .tabs > div:first-child {
    display: none;
  }

  .table-dark {
    color: inherit;
    color: white;
  }

  .table {
    border-collapse: collapse !important;
    border-collapse: inherit !important;
  }

  .table td, .table th {
    background-color: inherit !important;
  }

  #line-chart {
    max-width: 100%;
  }

  .col-sm-10.offset-sm-1.col-12 p.mb-5 {
    display: none;
  }
}
</style>

<style scoped lang="scss">
/deep/ .daterangepicker .calendar-table td, /deep/ .daterangepicker .calendar-table th {
  border-radius: 0;
}

h3 {
  line-height: 38px;
}

.domain-select {
  max-width: 350px;

  select {
    width: 100%;
  }
}

h4, h5 {
  text-transform: uppercase;
  font-weight: 600;
}

h4 {
  color: #fff;
}

.global-stats {
  h5 {
    font-size: 13px;
    color: #b9b9b9;
  }

  div {
    font-size: 33px;
    font-weight: 400;
    color: #fff
  }
}

.table-dark {
  background-color: transparent;
}

/deep/ table {
  font-size: 15px;
  border-collapse: separate;
  border-spacing: 0 8px;

  thead th {
    text-transform: uppercase;
    font-size: 13px;

    //&:nth-child(2), &:nth-child(7) {
    //  text-align: center;
    //}
  }

  tbody tr {
    background-color: #53575B;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);

    > td {
      padding: 7px 8px;

      &.image:first-child {
        padding: 0;
        max-width: 50px;
      }

      &.center {
        text-align: center;
        font-weight: 700;
      }

      &:nth-child(4) {
        ul {
          list-style: none;
          padding-left: 0;
          margin-bottom: 0;

          li {
            display: block;
            padding: 0 5px;
            margin-right: 6px;
            font-size: 13px;
            font-weight: 700;
            color: #44484C;
            background-color: #B9B9B9;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .cell-background-image {
    width: 100%;
    height: 100%;
    min-height: 36px;
    min-width: 50px;
    background-color: #777C7F;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .cell-folder {
    background: rgba(83, 220, 134, 0.7);

    svg {
      fill: white;
    }
  }
}

hr {
  margin-top: 0;
}

.dropdown-icon {
  color: #A9B1B5;
  position: absolute;
  right: 10px;
  top: 10px;
}

/deep/ .reportrange-text {
  position: relative;
  background: #53575B;
  color: #B9B9B9;
  border: 1px solid #707070;
  padding: 10px 50px 2px 15px;
  line-height: 16px;
}

/deep/ #line-chart {
  min-height: 400px;
}

#content .query-error {
  font-family: 'Source Sans Pro', sans-serif;
  color: #fff;
  background-color: #53575B;
  border: 1px solid rgba(220, 83, 83, 0.7);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
  font-size: 15px;
  padding: 7px 8px;
}
.country-table /deep/ tr > td {
  text-transform: uppercase;
}
</style>
